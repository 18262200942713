import { render, staticRenderFns } from "@/components/product/ProductBanner.pug?vue&type=template&id=13729b68&scoped=true&lang=pug&"
import script from "./ProductBanner.vue?vue&type=script&lang=ts&"
export * from "./ProductBanner.vue?vue&type=script&lang=ts&"
import style0 from "./ProductBanner.vue?vue&type=style&index=0&id=13729b68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13729b68",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCheckbox,VCol,VProgressCircular,VRow,VSpacer})
