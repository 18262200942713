




import { ImageInterface } from '@/store/interfaces/common/Image';
import XrImg from '@/components/image/XrImg.vue';
import { ProductReferenceImageInterface } from '@/store/interfaces/ProductReferenceImage';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'xr-image-roll-img',
  components: {
    XrImg,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    image: Object as PropType<ImageInterface[]>,
    imageHeight: {
      type: Number,
      default: 100,
    },
    imageWidth: {
      type: Number,
      default: 100,
    },
    showPrimaryImageStar: {
      type: Boolean,
      default: false,
    },
    showSecondaryImageStar: {
      type: Boolean,
      default: false,
    },
    showRemoveImage: {
      type: Boolean,
      default: false,
    },
    onClickImage: {
      type: Function,
      default: (image: ProductReferenceImageInterface) => {},
    },
    onClickRemove: {
      type: Function,
      default: (image: ProductReferenceImageInterface) => {},
    },
    onClickSecondaryStar: {
      type: Function,
      default: (image: ProductReferenceImageInterface) => {},
    },
  },
});
