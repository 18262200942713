




import Vue from 'vue';
import ProductDownloadZip from '@/components/product/ProductDownloadZip.vue';
import { mapState } from 'vuex';
import store from '@/store/index';
import backend from '@/services/3xrCom';

export default Vue.extend({
  name: 'product-banner',
  components: {
    ProductDownloadZip,
  },
  computed: {
    favorite: {
      get: function (): boolean {
        return this.product.favorites?.length > 0 || false;
      },
      set: function (newValue: boolean) {
        backend.post('product/set_favorite', {
          uid: this.product.uid,
          set: newValue,
        });
      },
    },
    isAdmin: () => store.getters.user.isAdmin,
    isClient: () => store.getters.user.isClient,
    ...mapState({
      asset: (state: any) => state.asset.asset,
      product: (state: any) => state.product.product,
    }),
  },
  props: {
    activeTab: Number,
    loading: Boolean,
  },
});
