




import Vue, { VueConstructor } from 'vue';

interface AddImgRefs {
  $refs: {
    uploader: HTMLElement;
  };
}

// Based on: https://codepen.io/blachocolat/pen/BgMKRQ
export default (Vue as VueConstructor<Vue & AddImgRefs>).extend({
  name: 'xr-add-img',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    imageHeight: {
      type: Number,
      default: 100,
    },
    imageWidth: {
      type: Number,
      default: 100,
    },
    onChangeImage: {
      type: Function,
      default: (files: File[]) => {},
    },
  },
  methods: {
    onButtonClick(): void {
      this.$refs.uploader.click();
    },
    onFileChanged(e: any): void {
      if (this.onChangeImage) {
        this.onChangeImage(e.target.files);
      }
    },
  },
});
